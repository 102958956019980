import {delay, catchError} from 'rxjs/internal/operators';

import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import {of, zip,  BehaviorSubject,   Observable} from 'rxjs';
import { Puerto } from '../../../models/puerto';

import { AuthService } from '../../../services/authService';
import { TipoAccion } from 'src/app/models/tipoAccion';
import { CartaPorte } from 'src/app/models/cartaPorte';
import { UtilsService } from 'src/app/services/utilsService';

@Component({
    selector: 'accion-dialog',
    templateUrl: 'accionDialog.html',
    styleUrls: ['./accionDialog.scss']
})
export class AccionDialog {

    spinner = false;
    continuar = false;
    cartasSeleccionadas: CartaPorte[] = [];

    displayedColumns  = [
        'nroCP',
        'situacion',
        'turno',
        'titular',
        'corredor',
        'especie',
        'destino',
        'procedencia'
    ];

    listasDesplegables: {
        situaciones: TipoAccion[],
        destinos: Observable<Puerto[]>
    } = { situaciones: [], destinos: null };

    destinoEnComentarios: boolean = false;

    // Datos de la nueva accion
    datosAccion: {
        puertoNuevo: Puerto,
        comentario: string,
        tipoAccion: TipoAccion
    } = { puertoNuevo: new Puerto(), comentario: '', tipoAccion: new TipoAccion() }

    constructor(
        public dialogRef: MatDialogRef<AccionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private authService: AuthService,
        private utilsService: UtilsService
    ) {
        this.cartasSeleccionadas = data.cartasSeleccionadas;
        this.listasDesplegables = this.getListasDesplegables();
    }

    getListasDesplegables = () => {
        return {
            // situaciones: ['Autorizar', 'Desviar', 'Solicitar Revision'],
            situaciones: [
                new TipoAccion({tipo: 1, descripcion: 'Autorizar'}),
                new TipoAccion({tipo: 2, descripcion: 'Desviar'}),
                new TipoAccion({tipo: 3, descripcion: 'Solicitar Revision'}),
                new TipoAccion({tipo: 4, descripcion: 'Recibidor Oficial'})
            ],
            destinos: this.authService.getDestinos()
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    /**
     * Registra la accion en cada carta seleccionada
     */
    onClickAceptar = () => {

        this.spinner = true;

        // Mapeo todas las cartas seleccionadas como observables de las request a hacer
        const accionesObservables = this.cartasSeleccionadas.map(
            carta => this.authService.setAccion(carta)(this.datosAccion)
        );

        // Ejecuto todas las request en paralelo
        zip(...accionesObservables)
            .pipe(
                catchError((err, caught) => of(err))
            )
            .subscribe(result => {
                this.spinner = false;
                if (result && result.length > 0) {
                    this.dialogRef.close(this.datosAccion);
                } else {
                    // Disparo error
                    this.utilsService.openDefaultDialog('Error')('No se pudo realizar la acción requerida');
                }
            })
    }

}
