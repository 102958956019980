export class Estado {
    estado: number;
    descripcion: string

    constructor(estado?: {
        estado: number;
        descripcion: string
    }) {
        if (estado) {
            this.estado = estado.estado;
            this.descripcion = estado.descripcion
        } else {
            this.estado = null;
            this.descripcion = null
        }
    }

}
