export class ListaDespModel {
    especies: any[];
    destinos: any[];
    situaciones: any[];
    entregadores: any[];
    titulares: any[];
    intermediarios: any[];
    remitentesCom: any[];
    corredores: any[];
    destinatarios: any[];
    procedencias: any[];
    estadoHistoria: any[];

    constructor(listaDespModel?: {
        especies: any[],
        destinos: any[],
        situaciones: any[],
        entregadores: any[],
        titulares: any[],
        intermediarios: any[];
        remitentesCom: any[];
        corredores: any[];
        destinatarios: any[];
        procedencias: any[];
        estadoHistoria: any[];
    }){
        if (listaDespModel) {
            this.especies = listaDespModel.especies;
            this.destinos = listaDespModel.destinos;
            this.situaciones = listaDespModel.situaciones;
            this.entregadores = listaDespModel.entregadores;
            this.titulares = listaDespModel.titulares;
            this.intermediarios = listaDespModel.intermediarios;
            this.remitentesCom = listaDespModel.remitentesCom;
            this.corredores = listaDespModel.corredores;
            this.destinatarios = listaDespModel.destinatarios;
            this.procedencias = listaDespModel.procedencias;
            this.estadoHistoria = listaDespModel.estadoHistoria;
        } else {
            this.especies = null;
            this.destinos = null;
            this.situaciones = null;
            this.entregadores = null;
            this.titulares = null;
            this.intermediarios = null;
            this.remitentesCom = null;
            this.corredores = null;
            this.destinatarios = null;
            this.procedencias = null;
            this.estadoHistoria = null;
        }
    }
}
