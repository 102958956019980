import * as _ from 'lodash';
import * as moment from 'moment';
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { CartaPorte } from "src/app/models/cartaPorte";
import { Analisis } from "src/app/models/analisis";
import { ListaDespModel } from "src/app/models/listaDespModel";
import { constants } from 'src/app/environment/constants';
import { FiltrosModel } from 'src/app/models/filtrosModel';
import { CartaPorteDetalle } from 'src/app/models/cartaPorteDetalle';
import { AuthService } from 'src/app/services/authService';
import { FilesService } from 'src/app/services/filesService';
import { Notificacion } from 'src/app/models/notificacion';
import { NotificacionesService } from 'src/app/services/notificacionesService';
import { NoticiasService } from 'src/app/services/noticiasService';


@Injectable()
export class CartaPorteService {

    constructor(
        public filesService: FilesService,
        private authService: AuthService,
        private notificacionesService: NotificacionesService,
        private noticiasService: NoticiasService
    ) { }
    /**
     * Acota los string muy largos que se van a mostrar en la tabla
     */
    acotarString = (elString: string) => (especial?) => {

        if (especial && especial === 'entreCp') {

            return elString ? elString.substr(0, 10) : elString;

        }

        if (elString && elString.length > 80) {
            return elString.substr(0, 80);
        } else {
            return elString;
        }
    }

    /**
     *
     */
    getClassByCarta = (rowCarta: CartaPorte) => {
        if (rowCarta && rowCarta.estadoPosiReal && rowCarta.estadoPosiReal.toLocaleLowerCase) {
            return 'estado-'+rowCarta.estadoPosiReal.toLocaleLowerCase()
        } else {
            return 'estado-null'
        }
    }

    // parseAnalisis = (a: Analisis) =>
    //     `${a.rbr_abrev}: ${a.anl_porc_analisis}% ${a.anl_porc_merma !== 0 ? `M: ${a.anl_porc_merma}%` : ''}`;

    parseAnalisis = (a: Analisis) =>
        `${a.rbr_abrev}: ${a.anl_porc_analisis}% ${a.anl_porc_merma !== 0 ? `${
            a.rbr_abrev === 'HD' ? 'M' : 'R'
        }: ${a.anl_porc_merma}%` : ''}`;


    /**
     * Retorna los arrays inciiales cuando inicia la talba posicion
     */
    // getArraysIniciales = async(posicionItems: CartaPorte[]) => new ListaDespModel(
    getArraysIniciales = (posicionItems: CartaPorte[]) => new ListaDespModel(
        {
            especies:           _.uniqBy(
                                    posicionItems.map(a => a.especie),
                                    'codmerca'
                                ),
            // especies:           this.uniq(posicionItems.map(a => a.cereal.trim()).sort()),
            // especies:           await this.authService.getEspecies().toPromise(),
            destinos:           _.uniqBy(posicionItems
                                    .map(a=>a.puertoDestino).sort(
                                        (a,b) =>    a.ptoRazon > b.ptoRazon ? 1 :
                                                    a.ptoRazon < b.ptoRazon ? -1 : 0
                                    ), 'ptoCodinterno').filter(puerto => puerto.ptoCodinterno !== 0),
            situaciones:        ['Descargadas', 'No descargadas', 'Con problemas', 'Con acciones'],
            entregadores:       _.uniqBy(
                                    posicionItems.map(a=>a.entregador).sort(this.orderByDeno),
                                    'cuit'
                                ),
            titulares:          _.uniqBy(posicionItems.map(a=>a.titular).sort(this.orderByDeno), 'cuit'),
            intermediarios:     _.uniqBy(posicionItems.map(a=>a.intermediario).sort(this.orderByDeno), 'cuit').filter(a => a.cuit !== 0),
            remitentesCom:      _.uniqBy(posicionItems.map(a=>a.remCom).sort(this.orderByDeno), 'cuit').filter(a => a.cuit !== 0),
            corredores:         _.uniqBy(
                [...posicionItems.map(a => a.corredor), ...posicionItems.map(a => a.corredor2)].sort(this.orderByDeno),
                'cuit'
            ).filter(a => a.cuit !== 0),
            destinatarios:      _.uniqBy(posicionItems.map(a=>a.destinatario).sort(this.orderByDeno), 'cuit').filter(a => a.cuit !== 0),
            procedencias:       _.uniqBy(posicionItems.map(a=>a.procedencia).sort(this.orderByDeno), 'cod').filter(a => a.cod !== 0),
            estadoHistoria:     [{
                                    porteEstado: 4,
                                    nombre: 'Descargado'
                                }, {
                                    porteEstado: 5,
                                    nombre: 'Desviado'
                                }]
        }
    )


    /**
     * Filtra los datos de la tabla de acuerdo a un fultro
     * @param filtro El valor del filtro. Ejemplo: 'soja'
     * @param tipoFiltro El tipo. Ejemplo: 'cereal'
     * @param e Evento. Se usa en el radio
     */
    filtrar = (posicionItems: CartaPorte[], filtrosActivos: FiltrosModel) => {
        let posicionFiltrada = posicionItems;

        if (filtrosActivos.entrega) {
            if (!filtrosActivos.entrega.propia) {
                posicionFiltrada = posicionFiltrada.filter(
                    cp => cp.entreCp != null && cp.entreCp.length > 0
                );
            }
            if (!filtrosActivos.entrega.otras) {
                posicionFiltrada = posicionFiltrada.filter(
                    cp => cp.entreCp == null || cp.entreCp.length <= 0
                );
            }
        }

        if (filtrosActivos.situacion) {
            let hoy = new Date();
            hoy.setHours(0);
            hoy.setMinutes(0);
            hoy.setSeconds(0);
            hoy.setMilliseconds(0);
            if (filtrosActivos.situacion === 'Descargadas') {
                posicionFiltrada = posicionFiltrada.filter(cp => cp.porteEstado === constants.porteEstado.DESCARGADO)
            }

            if (filtrosActivos.situacion === 'No descargadas') {
                posicionFiltrada = posicionFiltrada.filter(
                   cp =>    cp.porteEstado === 3 &&
                            cp.estado.estado !== 1
                );
            }

            if (filtrosActivos.situacion === 'Con problemas') {
                // Si esta rechazado o demorado
                posicionFiltrada = posicionFiltrada.filter(
                    cp =>   (
                                cp.porteEstado === constants.porteEstado.RECHAZADO &&
                                cp.estado.estado === constants.estadosEnPuertos.POSICION
                            ) || (
                                cp.porteEstado === constants.porteEstado.DEMORADO &&
                                cp.estado.estado === constants.estadosEnPuertos.DEMORADO
                            )

                )
            }

            // Acá cambié el AND por un OR (Osea en vez de poner && puse ||). Al parecer en la vieja web estaba filtrando mal, porque ese AND en la consulta no tiene sentido
            if (filtrosActivos.situacion === 'Con acciones') {
                /*
                    AND (estado_posi = 10 AND estado_posi = 11)
                */
                posicionFiltrada = posicionFiltrada.filter(
                    cp => cp.estado.estado === 10 || cp.estado.estado === 11
                )
            }


        }

        if (filtrosActivos.especie) {
            posicionFiltrada = posicionFiltrada.filter(
                cp => cp.especie.codmerca === filtrosActivos.especie.codmerca
            )
        }

        if (filtrosActivos.destino) {
            posicionFiltrada = posicionFiltrada.filter(
                cp => cp.puertoDestino.ptoCodinterno === filtrosActivos.destino
            )
        }

        if (filtrosActivos.entregador) {
            posicionFiltrada = posicionFiltrada.filter(
                cp => cp.entregador.cuit === filtrosActivos.entregador
            )
        }

        if (filtrosActivos.titular) {
            posicionFiltrada = posicionFiltrada.filter(
                cp => cp.titular.cuit === filtrosActivos.titular
            )
        }

        if (filtrosActivos.intermediario) {
            posicionFiltrada = posicionFiltrada.filter(
                cp => cp.intermediario.cuit === filtrosActivos.intermediario
            )
        }

        if (filtrosActivos.remComercial) {
            posicionFiltrada = posicionFiltrada.filter(
                cp => cp.remCom.cuit === filtrosActivos.remComercial
            )
        }

        if (filtrosActivos.corredor) {
            posicionFiltrada = posicionFiltrada.filter(
                cp =>
                    cp.corredor.cuit === filtrosActivos.corredor ||
                    cp.corredor2.cuit === filtrosActivos.corredor
            )
        }

        if (filtrosActivos.destinatario) {
            posicionFiltrada = posicionFiltrada.filter(
                cp => cp.destinatario.cuit === filtrosActivos.destinatario
            )
        }

        if (filtrosActivos.procedencia) {
            posicionFiltrada = posicionFiltrada.filter(
                cp => cp.procedencia.cod === filtrosActivos.procedencia
            )
        }

        if (filtrosActivos.estadoHistoria) {
            posicionFiltrada = posicionFiltrada.filter(
                cp => cp.porteEstado === filtrosActivos.estadoHistoria
            )
        }

        return posicionFiltrada;
    }

    /**
     * Retorna los filtors activos de acuerdo a unos parametros
     */
    getFiltrosActivos = (filtrosActivos) => (tipoFiltro) => (filtro) => {
        let newFiltrosActivo = _.clone(filtrosActivos);

        if (tipoFiltro === 'none') {
            return newFiltrosActivo;
        } else if (tipoFiltro === 'filtroEspecial' && filtro === 'todas') {
            // Seteo todo en null
            Object.keys(newFiltrosActivo).forEach(key => newFiltrosActivo[key] = null)
        } else {
            // Necesario para meterme en una key profunda de filtrosActivos (por ahora se usa solo en checkbox)
            if (tipoFiltro.indexOf('.') !== -1) {
                const keysTipo = tipoFiltro.split('.');
                newFiltrosActivo[keysTipo[0]][keysTipo[1]] = filtro;
            } else {
                newFiltrosActivo[tipoFiltro] = filtro;
            }
        }

        return newFiltrosActivo;
    }

    // Ahora filtro la posicion excel obtenido en base a los filtros seteados
    // SOLO SI el estadoAnterior era Filtrada
    generarExcel = (dataSource) => (estadoAnterior) => (tipoTabla) => (fechaDesde?) => (fechaHasta?) =>
        this.authService.getDataExcel(
            tipoTabla
        )(
            moment(fechaDesde).format("DD/MM/YYYY")
        )(
            moment(fechaHasta).format("DD/MM/YYYY")
        ).pipe(
            map(
                (dataExcel: CartaPorteDetalle[]) => {
                    // Si hay filtros activos solo genero excel de las cartas filtradas
                    if (estadoAnterior === constants.estadosTablaPosi.FILTRADA) {
                        dataSource.getCartasSubject().subscribe(posiFiltrada => {
                            const dataExcelFiltrada = dataExcel.filter(
                                cartaExcel => posiFiltrada.some(
                                    cartaFilt =>    cartaFilt.PorteNro === cartaExcel.Porte_nro &&
                                                    cartaFilt.porteVagon === cartaExcel.Porte_vagon &&
                                                    cartaFilt.entregador.cuit === cartaExcel.Porte_cuit_entregador &&
                                                    cartaFilt.porteCodPuerto === cartaExcel.Porte_cod_puerto
                                )
                            );
                            this.filesService.descargarExcel(dataExcelFiltrada);
                        }).unsubscribe();
                    } else {
                        // Sino, genero excel de todo
                        this.filesService.descargarExcel(dataExcel);
                    }

                    // Vuelvo al estado anterior
                    return estadoAnterior
                }
            )
        )


    /**
     * Genera el analisis de la posicion
     */
    generarAnalisis = (estadoTablaPosicion) => (cartasTotal) => (dataSource) => {
        if (estadoTablaPosicion === constants.estadosTablaPosi.FILTRADA) {
            dataSource.getCartasSubject().subscribe(
                posiFiltrada => this.filesService.generarTxtAnalisis(posiFiltrada)
            ).unsubscribe();
        } else {
            this.filesService.generarTxtAnalisis(cartasTotal)
        }

    }

    /**
     * Filtra por valor de busqueda
     */
    filtroBusqueda = (posicionTotal: CartaPorte[], valueBusqueda) => posicionTotal.filter(
        carta =>    this.evaluateBusquedaKey(valueBusqueda)('nroCTG')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('PorteNro')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('estadoPosiReal')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('porteTurno')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('titular')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('intermediario')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('remCom')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('corredor')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('cereal')(carta) ||
                    carta.puertoDestino.ptoRazon.toString().toLowerCase().includes(valueBusqueda.toLowerCase()) ||
                    this.evaluateBusquedaKey(valueBusqueda)('destinatario')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('calidadCldNomenclatura')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('obsAnalisis')(carta) ||
                    carta.analisis && carta.analisis.length > 0 && carta.analisis.some(
                        a => this.parseAnalisis(a).toLowerCase().includes(valueBusqueda.toLowerCase())
                    ) ||
                    this.evaluateBusquedaKey(valueBusqueda)('portePatenteCamion')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('obsEstado')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('procedencia')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('porteKgsProcede')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('porteKgsNeto')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('entregador')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('entreCp')(carta) ||
                    this.evaluateBusquedaKey(valueBusqueda)('porteObservacion')(carta)
    )

    /**
     * Evalua una key dada
     */
    evaluateBusquedaKey = (valueBusqueda) => (nombreKey) => (carta) => {
        if (carta[nombreKey]) {
            if (carta[nombreKey].denominacion) {
                return carta[nombreKey] && carta[nombreKey].denominacion.toString().toLowerCase().includes(valueBusqueda.toLowerCase())
            } else {
                return carta[nombreKey] && carta[nombreKey].toString().toLowerCase().includes(valueBusqueda.toLowerCase())
            }
        }
    }



    /**
     * Mira si hay nuevos con problemas, y muestra las notificaciones
     * También checkeo que si alguna dejó de tener problemas, y las borro de las notificaciones
     */
    actualizarNotificaciones = (posiVieja) => (posiNueva) => {
        // Agarro los con problemas
        const conProblemasViejos = this.filtrar(
            posiVieja,
            this.getFiltrosActivos(
                new FiltrosModel()
            )(
                'situacion'
            )(
                'Con problemas'
            )
        );

        const conProblemasNuevos = this.filtrar(
            posiNueva,
            this.getFiltrosActivos(
                new FiltrosModel()
            )(
                'situacion'
            )(
                'Con problemas'
            )
        );

        // Si los nuevos son MÁS, entonces hay nuevos
        if (conProblemasViejos.length < conProblemasNuevos.length) {
            // Agarro los nuevos
            const nuevosConProblemas = conProblemasNuevos.filter(
                (cpN: CartaPorte) => !conProblemasViejos.some(
                    (cpV: CartaPorte) =>    cpV.PorteNro === cpN.PorteNro &&
                                            cpV.entregador.cuit === cpN.entregador.cuit &&
                                            cpV.porteCodPuerto === cpN.porteCodPuerto &&
                                            cpV.porteVagon === cpN.porteVagon
                )
            );

            // Creo las notificaciones
            const newNotifications = nuevosConProblemas && nuevosConProblemas.length > 0 ?
                nuevosConProblemas.map(ncP => new Notificacion({
                    tipo: ncP && ncP.estadoPosiReal && ncP.estadoPosiReal.toLowerCase() === 'rechazado' ?
                        constants.tiposNotificaciones.RECHAZADO : constants.tiposNotificaciones.DEMORADO,
                    carta: ncP
                })) : [];

            this.notificacionesService.addNotificaciones(newNotifications);
        }


        // Remuevo notificaciones obsoletas
        this.notificacionesService.removerNotifObsoletas(conProblemasNuevos)

    }


    /**
     * Retorna solo las con problemas
     */
    getConProblemas = (posi) =>
        this.filtrar(
            posi,
            this.getFiltrosActivos(
                new FiltrosModel()
            )(
                'situacion'
            )(
                'Con problemas'
            )
        );




    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////// Metodos privados ////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    /**
     * Remueve duplciados array
     * @param a array
     */
    private uniq = (a) => {
        // Si a NO tiene la property cuit quiere decir que NO es un json. Por lo que NO es el array de entregadores
        // if (a[0] && a[0].cuit) {
        //     // Si SI es el array de entregadores, debo filtrar por cuit
        //     const soloCuits = a.map(cp=>cp.cuit);
        //     const uniqSoloCuits = Array.from(new Set(soloCuits));
        //     return a.filter(cp => uniqSoloCuits.includes(cp.cuit));
        // } else {
            return Array.from(new Set(a));
        // }
    }

    /**
     * Mappea los items y retorna una lista de un dropdown
     * Es privado se usa solamente acá como auxiliar
     */
    private getArrayDropdown = (posicionItems) => (nombreDropdown) => _.uniqBy(
        posicionItems.map(a=>a[nombreDropdown]).sort(this.orderByDeno), 'cuit'
    )

    private orderByDeno = (a,b) =>  a.denominacion > b.denominacion ? 1 :
                                    a.denominacion < b.denominacion ? -1 : 0




}
