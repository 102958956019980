import * as moment from 'moment';
import { Injectable } from "@angular/core";
import { AuthService } from "./authService";
import { UtilsService } from "src/app/services/utilsService";

@Injectable()
export class HistoriaService {

    constructor(
        private authService: AuthService,
        private utilsService: UtilsService
    ) { }


    /**
     * Obtiene la historia de ciertos entregadores en un intervalo dado
     */
    getHistoria = (filtroFecha: {
        fechaDesde: Date,
        fechaHasta: Date
    }) => this.authService.getHistoria(
        moment(filtroFecha.fechaDesde).format("DD/MM/YYYY")
        // this.utilsService.dateToDdMmAaaa(filtroFecha.fechaDesde)
    )(
        moment(filtroFecha.fechaHasta).format("DD/MM/YYYY")
        // this.utilsService.dateToDdMmAaaa(filtroFecha.fechaHasta)
    );
    // {
    //     try {
    //         return this.authService.getHistoria('25/06/2018')('25/06/2018');
    //     }
    //     catch(err) {
    //         console.log(err);
    //     }
    // }

    /**
     * Retorna las fechas iniciales (fecha de ayer)
     */
    getFechasIniciales = () => {
        return {
            fechaDesde: this.utilsService.getYesterday(),
            fechaHasta: this.utilsService.getYesterday()
        }
    }

    /**
     * Checkea que la cantidad de dias a bsucar no supere los 3
     */
    checkValidesFechas = (filtroFecha: {
        fechaDesde: Date,
        fechaHasta: Date
    }) =>  {
        const date1 = moment(filtroFecha.fechaDesde);
        const date2 = moment(filtroFecha.fechaHasta);
        return date2.diff(date1, 'days') <= 3
    }

}
