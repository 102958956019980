export class constants {

    public static infiniteScrollSize = 15;

    // Local
    // public static urlBase: string = "http://localhost:8080/cerealnetpuertosrest/ws";

    // Testing Cerealnet
    //public static urlBase: string = "http://190.210.222.177:48080/cerealnetPuertosRestV2/ws";
    public static urlBase: string = "https://ws.cerealnet.com/cerealnetPuertosRestV2/ws";    
   

    // Testing
    //public static urlBase: string = "http://200.61.186.212:48080/cerealnetPuertosRest-1.0-SNAPSHOT/ws";
    //public static urlBase: string = "http://190.210.222.177:48080/cerealnetPuertosRest-1.0-SNAPSHOT/ws";    
    
    // Estados de las cartas (solo en los puertos) (sería estado_posi de la tabla PORTE)
    public static estadosEnPuertos = {
        POSICION: 0,
        DEMORADO: 1,
        A_DESCARGAR: 2,
        CALADO: 3,
        AUTORIZADO: 4,
        LABORATORIO: 5,
        GERENCIA: 6,
        DESVIO: 7,
        REC_OFICIAL: 8,
        CONSULTA: 9,
        ENTREGA_AUTORIZADO: 10,
        ENTREGA_DESVIADO: 11
    }

    public static tiposAcciones = {
        AUTORIZAR: 1,
        DESVIAR: 2,
        SOLICITAR_REVISION: 3
    }

    public static tiposNotificaciones = {
        DEMORADO: 1,
        RECHAZADO: 2
    }

    // Lista de estados de porteEstado
    public static porteEstado = {
        RECHAZADO: 5,
        DEMORADO: 3,
        DESCARGADO: 4
    }


    // Puertos, su Pto_razon y su Pto_Cod_interno
    public static puertos = {
        FCA_VICENTIN: 1,
        BUNGE_ARGENTINA_SA: 5,
        CARGILL_QUEBRACHO: 9,
        MOLINOS_RIO_STA_CLARA: 13,
        DREYFUS_TIMBUES: 89
    }

    public static estadosTablaPosi = {
        INFINITE_SCROLL: 0,
        DESCARGANDO: 1,
        FILTRADA: 2
    }
}
