import { CartaPorte } from "./cartaPorte";

export class Notificacion {
    tipo: number;
    carta: CartaPorte;
    vista: boolean;
    fechaHora: any;

    constructor(notificacion?: {
        tipo: number;
        carta: any;
        fechaHora?: any;
    }) {
        if (notificacion) {
            this.tipo = notificacion.tipo;
            this.carta = notificacion.carta;
            this.fechaHora = notificacion.fechaHora ? notificacion.fechaHora : new Date();
            this.vista = false;
        } else {
            this.tipo = null;
            this.carta = null;
            this.fechaHora = null;
            this.vista = false;
        }
    }

}
