import {delay} from 'rxjs/internal/operators';

import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import {of, zip,  BehaviorSubject,   Observable} from 'rxjs';
import { Puerto } from '../../../models/puerto';

import { AuthService } from '../../../services/authService';
import { TipoAccion } from 'src/app/models/tipoAccion';
import { CartaPorte } from 'src/app/models/cartaPorte';

@Component({
    selector: 'default-dialog',
    templateUrl: 'defaultDialog.html',
    styleUrls: ['./defaultDialog.scss']
})
export class DefaultDialog {

    titulo: string = '';
    cuerpo: string = '';

    constructor(
        public dialogRef: MatDialogRef<DefaultDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        console.log(data);
        this.titulo = data.titulo;
        this.cuerpo = data.cuerpo;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    /**
     * Registra la accion en cada carta seleccionada
     */
    onClickAceptar = () => {

    }

}
