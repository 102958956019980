export class Procedencia {
    cod: number;
    denominacion: string;

    constructor(procedencia?: {
        cod: number;
        denominacion: string;
    }) {
        if (procedencia) {
            this.cod = procedencia.cod;
            this.denominacion = procedencia.denominacion;
        } else {
            this.cod = null;
            this.denominacion = null;
        }
    }

}
