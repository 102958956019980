import { Analisis } from "src/app/models/analisis";

export class CartaPorteDetalle {
    Porte_prefijo: string;
    Porte_nro: string;
    Porte_cuit_entregador: number;
    porte_cod_merca: number;
    Porte_cuit_corredor: number;
    Porte_cuit_destinatario: number;
    Porte_cod_procedencia: number;
    porte_kgs_procede: number;
    porte_contrato: string;
    porte_fecha_des: Date;
    porte_cuit_transp: number;
    porte_turno: number;
    porte_estado: number;
    porte_cuit_remitente: number;
    porte_cuit_cyo: number;
    porte_cuit_cyo2: number;
    porte_fecha_arr: Date;
    porte_bruto: number;
    porte_kgs_tara: number;
    porte_kgs_neto: number;
    Porte_medio: number;
    Porte_cod_puerto: number;
    Porte_patente_camion: string;
    Porte_hora_arr: Date;
    Porte_descar_obs: string;
    Porte_calidad: number;
    Porte_vagon: number;
    Porte_nro_planta: number;
    Porte_transp_razon: string;
    Porte_observacion: string;
    Porte_cod_transp: number;
    Porte_kilos_merma: number;
    porte_pre_desvio: number;
    porte_nro_desvio: number;
    Porte_recibo: number;
    porte_fecha_cp: Date;
    porte_tipo: number;
    Porte_nro_pla_cyo1: number;
    porte_nro_pla_cyo2: number;
    porte_nro_ant: number;
    porte_pre_ant: number;
    porte_vagon_ant: number;
    porte_neto_ant: number;
    porte_camara: string;
    porte_solic_analisis: number;
    porte_destin_exis: number;
    porte_cc: number;
    porte_autorizado: number;
    porte_cosecha: string;
    porte_imp_exp: number;
    porte_imp_ent: number;
    porte_tanda: number;
    porte_firma_transp: number;
    porte_ctg: number;
    porte_titular: string;
    porte_interm: string;
    porte_remi: string;
    destin: string;
    corre: string;
    cereal: string;
    proce: string;
    entre_cp: string;
    estado_posi: number;
    entre: string;
    obs_estado: string;
    Cod_merca: number;
    Descripcion_mercaderia: string;
    tipo_mercaderia: number;
    Cosecha: string;
    Tarifa_x_tonelada: number;
    total_planta: number;
    turno_calado: number;
    asig_turno: number;
    hd: number;
    estado_posi_des: string;
    Pto_Cod_interno: number;
    Pto_Cuit: number;
    Pto_Postal_onnca: number;
    Pto_Razon: string;
    Pto_Domi_Nro: number;
    pto_turno: string;
    pto_admin: number;
    pto_pplanta_act: number;
    pto_local_oncca: number;
    pto_control_turno: string;
    pto_planta: number;
    P_adic_cuit_chofer: number;
    P_adic_razon_chofer: string;
    P_adic_fecha_venc: Date;
    P_adic_bruto_proc: number;
    P_adic_tara_proc: number;
    P_adic_patente_acopl: string;
    P_adic_p_nro_cac: number;
    cld_nomenclatura: string;
    destinatariodeno: string;
    CP: string;
    analiDet: string;
    obs_analisis: string;

    analisis: Analisis[];

    aut_puerto: string;
    nom_corre2: string;
    corre2: number;
    ruta: string;
    obs_puerto: string;
    act_comentario: string;

    // 22-10-2021
    rt1: string; // Hace referencia al remitente comercial 2 de la DB
    cuit_rt1: number;
    rt2: string; // Hace referencia al remitente comercial 3 de la DB
    cuit_rt2: number;
    nroOperador: number;
    nro_cupo: string;
    nroSucursal: number;
    nroOrden: number;
    tipoDocumento: string;

    constructor(cartaPorteDetalle?: {
        Porte_prefijo: string;
        Porte_nro: string;
        Porte_cuit_entregador: number;
        porte_cod_merca: number;
        Porte_cuit_corredor: number;
        Porte_cuit_destinatario: number;
        Porte_cod_procedencia: number;
        porte_kgs_procede: number;
        porte_contrato: string;
        porte_fecha_des: Date;
        porte_cuit_transp: number;
        porte_turno: number;
        porte_estado: number;
        porte_cuit_remitente: number;
        porte_cuit_cyo: number;
        porte_cuit_cyo2: number;
        porte_fecha_arr: Date;
        porte_bruto: number;
        porte_kgs_tara: number;
        porte_kgs_neto: number;
        Porte_medio: number;
        Porte_cod_puerto: number;
        Porte_patente_camion: string;
        Porte_hora_arr: Date;
        Porte_descar_obs: string;
        Porte_calidad: number;
        Porte_vagon: number;
        Porte_nro_planta: number;
        Porte_transp_razon: string;
        Porte_observacion: string;
        Porte_cod_transp: number;
        Porte_kilos_merma: number;
        porte_pre_desvio: number;
        porte_nro_desvio: number;
        Porte_recibo: number;
        porte_fecha_cp: Date;
        porte_tipo: number;
        Porte_nro_pla_cyo1: number;
        porte_nro_pla_cyo2: number;
        porte_nro_ant: number;
        porte_pre_ant: number;
        porte_vagon_ant: number;
        porte_neto_ant: number;
        porte_camara: string;
        porte_solic_analisis: number;
        porte_destin_exis: number;
        porte_cc: number;
        porte_autorizado: number;
        porte_cosecha: string;
        porte_imp_exp: number;
        porte_imp_ent: number;
        porte_tanda: number;
        porte_firma_transp: number;
        porte_ctg: number;
        porte_titular: string;
        porte_interm: string;
        porte_remi: string;
        destin: string;
        corre: string;
        cereal: string;
        proce: string;
        entre_cp: string;
        estado_posi: number;
        entre: string;
        obs_estado: string;
        Cod_merca: number;
        Descripcion_mercaderia: string;
        tipo_mercaderia: number;
        Cosecha: string;
        Tarifa_x_tonelada: number;
        total_planta: number;
        turno_calado: number;
        asig_turno: number;
        hd: number;
        estado_posi_des: string;
        Pto_Cod_interno: number;
        Pto_Cuit: number;
        Pto_Postal_onnca: number;
        Pto_Razon: string;
        Pto_Domi_Nro: number;
        pto_turno: string;
        pto_admin: number;
        pto_pplanta_act: number;
        pto_local_oncca: number;
        pto_control_turno: string;
        pto_planta: number;
        P_adic_cuit_chofer: number;
        P_adic_razon_chofer: string;
        P_adic_fecha_venc: Date;
        P_adic_bruto_proc: number;
        P_adic_tara_proc: number;
        P_adic_patente_acopl: string;
        P_adic_p_nro_cac: number;
        cld_nomenclatura: string;
        destinatariodeno: string;
        CP: string;
        analiDet: string;
        obs_analisis: string;

        analisis: any[];

        aut_puerto: string;
        nom_corre2: string;
        corre2: number;    
        ruta: string;
        obs_puerto : string;
        act_comentario : string;

        // 22-10-2021
        rt1: {cuit: number, denominacion: string};
        rt2: {cuit: number, denominacion: string};
        nroOperador: number;
        nro_cupo: string;
        nroSucursal: number;
        nroOrden: number;
        tipoDocumento: string;
    }) {
        if (cartaPorteDetalle) {
            this.Porte_prefijo = cartaPorteDetalle.Porte_prefijo;
            this.Porte_nro = cartaPorteDetalle.Porte_nro;
            this.Porte_cuit_entregador = cartaPorteDetalle.Porte_cuit_entregador;
            this.porte_cod_merca = cartaPorteDetalle.porte_cod_merca;
            this.Porte_cuit_corredor = cartaPorteDetalle.Porte_cuit_corredor;
            this.Porte_cuit_destinatario = cartaPorteDetalle.Porte_cuit_destinatario;
            this.Porte_cod_procedencia = cartaPorteDetalle.Porte_cod_procedencia;
            this.porte_kgs_procede = cartaPorteDetalle.porte_kgs_procede;
            this.porte_contrato = cartaPorteDetalle.porte_contrato;
            this.porte_fecha_des = cartaPorteDetalle.porte_fecha_des;
            this.porte_cuit_transp = cartaPorteDetalle.porte_cuit_transp;
            this.porte_turno = cartaPorteDetalle.porte_turno;
            this.porte_estado = cartaPorteDetalle.porte_estado;
            this.porte_cuit_remitente = cartaPorteDetalle.porte_cuit_remitente;
            this.porte_cuit_cyo = cartaPorteDetalle.porte_cuit_cyo;
            this.porte_cuit_cyo2 = cartaPorteDetalle.porte_cuit_cyo2;
            this.porte_fecha_arr = cartaPorteDetalle.porte_fecha_arr;
            this.porte_bruto = cartaPorteDetalle.porte_bruto;
            this.porte_kgs_tara = cartaPorteDetalle.porte_kgs_tara;
            this.porte_kgs_neto = cartaPorteDetalle.porte_kgs_neto;
            this.Porte_medio = cartaPorteDetalle.Porte_medio;
            this.Porte_cod_puerto = cartaPorteDetalle.Porte_cod_puerto;
            this.Porte_patente_camion = cartaPorteDetalle.Porte_patente_camion;
            this.Porte_hora_arr = cartaPorteDetalle.Porte_hora_arr;
            this.Porte_descar_obs = cartaPorteDetalle.Porte_descar_obs;
            this.Porte_calidad = cartaPorteDetalle.Porte_calidad;
            this.Porte_vagon = cartaPorteDetalle.Porte_vagon;
            this.Porte_nro_planta = cartaPorteDetalle.Porte_nro_planta;
            this.Porte_transp_razon = cartaPorteDetalle.Porte_transp_razon;
            this.Porte_observacion = cartaPorteDetalle.Porte_observacion;
            this.Porte_cod_transp = cartaPorteDetalle.Porte_cod_transp;
            this.Porte_kilos_merma = cartaPorteDetalle.Porte_kilos_merma;
            this.porte_pre_desvio = cartaPorteDetalle.porte_pre_desvio;
            this.porte_nro_desvio = cartaPorteDetalle.porte_nro_desvio;
            this.Porte_recibo = cartaPorteDetalle.Porte_recibo;
            this.porte_fecha_cp = cartaPorteDetalle.porte_fecha_cp;
            this.porte_tipo = cartaPorteDetalle.porte_tipo;
            this.Porte_nro_pla_cyo1 = cartaPorteDetalle.Porte_nro_pla_cyo1;
            this.porte_nro_pla_cyo2 = cartaPorteDetalle.porte_nro_pla_cyo2;
            this.porte_nro_ant = cartaPorteDetalle.porte_nro_ant;
            this.porte_pre_ant = cartaPorteDetalle.porte_pre_ant;
            this.porte_vagon_ant = cartaPorteDetalle.porte_vagon_ant;
            this.porte_neto_ant = cartaPorteDetalle.porte_neto_ant;
            this.porte_camara = cartaPorteDetalle.porte_camara;
            this.porte_solic_analisis = cartaPorteDetalle.porte_solic_analisis;
            this.porte_destin_exis = cartaPorteDetalle.porte_destin_exis;
            this.porte_cc = cartaPorteDetalle.porte_cc;
            this.porte_autorizado = cartaPorteDetalle.porte_autorizado;
            this.porte_cosecha = cartaPorteDetalle.porte_cosecha;
            this.porte_imp_exp = cartaPorteDetalle.porte_imp_exp;
            this.porte_imp_ent = cartaPorteDetalle.porte_imp_ent;
            this.porte_tanda = cartaPorteDetalle.porte_tanda;
            this.porte_firma_transp = cartaPorteDetalle.porte_firma_transp;
            this.porte_ctg = cartaPorteDetalle.porte_ctg;
            this.porte_titular = cartaPorteDetalle.porte_titular;
            this.porte_interm = cartaPorteDetalle.porte_interm;
            this.porte_remi = cartaPorteDetalle.porte_remi;
            this.destin = cartaPorteDetalle.destin;
            this.corre = cartaPorteDetalle.corre;
            this.cereal = cartaPorteDetalle.cereal;
            this.proce = cartaPorteDetalle.proce;
            this.entre_cp = cartaPorteDetalle.entre_cp;
            this.estado_posi = cartaPorteDetalle.estado_posi;
            this.entre = cartaPorteDetalle.entre;
            this.obs_estado = cartaPorteDetalle.obs_estado;
            this.Cod_merca = cartaPorteDetalle.Cod_merca;
            this.Descripcion_mercaderia = cartaPorteDetalle.Descripcion_mercaderia;
            this.tipo_mercaderia = cartaPorteDetalle.tipo_mercaderia;
            this.Cosecha = cartaPorteDetalle.Cosecha;
            this.Tarifa_x_tonelada = cartaPorteDetalle.Tarifa_x_tonelada;
            this.total_planta = cartaPorteDetalle.total_planta;
            this.turno_calado = cartaPorteDetalle.turno_calado;
            this.asig_turno = cartaPorteDetalle.asig_turno;
            this.hd = cartaPorteDetalle.hd;
            this.estado_posi_des = cartaPorteDetalle.estado_posi_des;
            this.Pto_Cod_interno = cartaPorteDetalle.Pto_Cod_interno;
            this.Pto_Cuit = cartaPorteDetalle.Pto_Cuit;
            this.Pto_Postal_onnca = cartaPorteDetalle.Pto_Postal_onnca;
            this.Pto_Razon = cartaPorteDetalle.Pto_Razon;
            this.Pto_Domi_Nro = cartaPorteDetalle.Pto_Domi_Nro;
            this.pto_turno = cartaPorteDetalle.pto_turno;
            this.pto_admin = cartaPorteDetalle.pto_admin;
            this.pto_pplanta_act = cartaPorteDetalle.pto_pplanta_act;
            this.pto_local_oncca = cartaPorteDetalle.pto_local_oncca;
            this.pto_control_turno = cartaPorteDetalle.pto_control_turno;
            this.pto_planta = cartaPorteDetalle.pto_planta;
            this.P_adic_cuit_chofer = cartaPorteDetalle.P_adic_cuit_chofer;
            this.P_adic_razon_chofer = cartaPorteDetalle.P_adic_razon_chofer;
            this.P_adic_fecha_venc = cartaPorteDetalle.P_adic_fecha_venc;
            this.P_adic_bruto_proc = cartaPorteDetalle.P_adic_bruto_proc;
            this.P_adic_tara_proc = cartaPorteDetalle.P_adic_tara_proc;
            this.P_adic_patente_acopl = cartaPorteDetalle.P_adic_patente_acopl;
            this.P_adic_p_nro_cac = cartaPorteDetalle.P_adic_p_nro_cac;
            this.cld_nomenclatura = cartaPorteDetalle.cld_nomenclatura;
            this.destinatariodeno = cartaPorteDetalle.destinatariodeno;
            this.CP = cartaPorteDetalle.CP;
            this.analiDet = cartaPorteDetalle.analiDet;
            this.obs_analisis = cartaPorteDetalle.obs_analisis;

            this.analisis = cartaPorteDetalle.analisis.map(a=>new Analisis(a));
            this.aut_puerto = cartaPorteDetalle.aut_puerto;

            this.nom_corre2 = cartaPorteDetalle.nom_corre2;
            this.corre2 = cartaPorteDetalle.corre2;
            this.ruta = cartaPorteDetalle.ruta;
            this.obs_puerto = cartaPorteDetalle.obs_puerto;
            this.act_comentario = cartaPorteDetalle.act_comentario;

            if(cartaPorteDetalle.rt1) {
                this.rt1 = cartaPorteDetalle.rt1.denominacion;
                this.cuit_rt1 = cartaPorteDetalle.rt1.cuit;
            }

            if(cartaPorteDetalle.rt2) {
                this.rt2 = cartaPorteDetalle.rt2.denominacion;
                this.cuit_rt2 = cartaPorteDetalle.rt2.cuit;
            }

            this.nroOperador = cartaPorteDetalle.nroOperador;
            this.nro_cupo = cartaPorteDetalle.nro_cupo;
            this.nroSucursal = cartaPorteDetalle.nroSucursal;
            this.nroOrden = cartaPorteDetalle.nroOrden;
            this.tipoDocumento = cartaPorteDetalle.tipoDocumento;

        } else {
            this.Porte_prefijo = null;
            this.Porte_nro = null;
            this.Porte_cuit_entregador = null;
            this.porte_cod_merca = null;
            this.Porte_cuit_corredor = null;
            this.Porte_cuit_destinatario = null;
            this.Porte_cod_procedencia = null;
            this.porte_kgs_procede = null;
            this.porte_contrato = null;
            this.porte_fecha_des = null;
            this.porte_cuit_transp = null;
            this.porte_turno = null;
            this.porte_estado = null;
            this.porte_cuit_remitente = null;
            this.porte_cuit_cyo = null;
            this.porte_cuit_cyo2 = null;
            this.porte_fecha_arr = null;
            this.porte_bruto = null;
            this.porte_kgs_tara = null;
            this.porte_kgs_neto = null;
            this.Porte_medio = null;
            this.Porte_cod_puerto = null;
            this.Porte_patente_camion = null;
            this.Porte_hora_arr = null;
            this.Porte_descar_obs = null;
            this.Porte_calidad = null;
            this.Porte_vagon = null;
            this.Porte_nro_planta = null;
            this.Porte_transp_razon = null;
            this.Porte_observacion = null;
            this.Porte_cod_transp = null;
            this.Porte_kilos_merma = null;
            this.porte_pre_desvio = null;
            this.porte_nro_desvio = null;
            this.Porte_recibo = null;
            this.porte_fecha_cp = null;
            this.porte_tipo = null;
            this.Porte_nro_pla_cyo1 = null;
            this.porte_nro_pla_cyo2 = null;
            this.porte_nro_ant = null;
            this.porte_pre_ant = null;
            this.porte_vagon_ant = null;
            this.porte_neto_ant = null;
            this.porte_camara = null;
            this.porte_solic_analisis = null;
            this.porte_destin_exis = null;
            this.porte_cc = null;
            this.porte_autorizado = null;
            this.porte_cosecha = null;
            this.porte_imp_exp = null;
            this.porte_imp_ent = null;
            this.porte_tanda = null;
            this.porte_firma_transp = null;
            this.porte_ctg = null;
            this.porte_titular = null;
            this.porte_interm = null;
            this.porte_remi = null;
            this.destin = null;
            this.corre = null;
            this.cereal = null;
            this.proce = null;
            this.entre_cp = null;
            this.estado_posi = null;
            this.entre = null;
            this.obs_estado = null;
            this.Cod_merca = null;
            this.Descripcion_mercaderia = null;
            this.tipo_mercaderia = null;
            this.Cosecha = null;
            this.Tarifa_x_tonelada = null;
            this.total_planta = null;
            this.turno_calado = null;
            this.asig_turno = null;
            this.hd = null;
            this.estado_posi_des = null;
            this.Pto_Cod_interno = null;
            this.Pto_Cuit = null;
            this.Pto_Postal_onnca = null;
            this.Pto_Razon = null;
            this.Pto_Domi_Nro = null;
            this.pto_turno = null;
            this.pto_admin = null;
            this.pto_pplanta_act = null;
            this.pto_local_oncca = null;
            this.pto_control_turno = null;
            this.pto_planta = null;
            this.P_adic_cuit_chofer = null;
            this.P_adic_razon_chofer = null;
            this.P_adic_fecha_venc = null;
            this.P_adic_bruto_proc = null;
            this.P_adic_tara_proc = null;
            this.P_adic_patente_acopl = null;
            this.P_adic_p_nro_cac = null;
            this.cld_nomenclatura = null;
            this.destinatariodeno = null;
            this.CP = null;
            this.analiDet = null;
            this.obs_analisis = null;

            this.analisis = [];

            this.aut_puerto = null;
            this.nom_corre2 = cartaPorteDetalle.nom_corre2;
            this.corre2 = cartaPorteDetalle.corre2;       
            this.ruta = cartaPorteDetalle.ruta;
            this.obs_puerto = cartaPorteDetalle.obs_puerto;
            this.act_comentario = cartaPorteDetalle.act_comentario;

            if(cartaPorteDetalle.rt1) {
                this.rt1 = cartaPorteDetalle.rt1.denominacion;
                this.cuit_rt1 = cartaPorteDetalle.rt1.cuit;
            }

            if(cartaPorteDetalle.rt2) {
                this.rt2 = cartaPorteDetalle.rt2.denominacion;
                this.cuit_rt2 = cartaPorteDetalle.rt2.cuit;
            }
            
            this.nroOperador = cartaPorteDetalle.nroOperador;
            this.nro_cupo = cartaPorteDetalle.nro_cupo;
            this.nroSucursal = cartaPorteDetalle.nroSucursal;
            this.nroOrden = cartaPorteDetalle.nroOrden;
            this.tipoDocumento = cartaPorteDetalle.tipoDocumento;            
        }
    }

    getNumeroCPE(){
        let sNroSucursal = '0000'+ this.nroSucursal;
        let sNroOrden = '0000000'+ this.nroOrden;        
        let sNroCPE = sNroSucursal.substring(sNroSucursal.length -5) + '-' + sNroOrden.substring(sNroOrden.length -8);
        return sNroCPE;
    }

    getOtrosIntervinientes(){
        let inter1 = "";
        let inter2 = "";

        if(this.rt1){
            inter1 = "("+ this.cuit_rt1 + ") " + this.rt1;
        }

        if(this.rt2){
            inter2 = "("+ this.cuit_rt2 + ") " + this.rt2;
        }        

        return inter1 + " - " + inter2;
    }

    getNroVagonOChasis(){
        if(this.Porte_vagon && this.Porte_vagon > 0){
            return this.Porte_vagon;
        }
        return this.Porte_patente_camion;
    }
}
