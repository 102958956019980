import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localStorageService';
import { Acceso } from '../models/acceso';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private localStorageService: LocalStorageService
    ) {}

   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       // Busco el acceso activo para tomar el token
       const accesoActivo: Acceso = this.localStorageService.getObject('accesoActivo') ? this.localStorageService.getObject('accesoActivo').acceso : null;
       //const accesoActivo: Acceso = this.localStorageService.getObject('accesoActivo') ? this.localStorageService.getObject('accesoActivo') : null;
       return accesoActivo ?    next.handle(req.clone({headers: req.headers.set('token', `${accesoActivo.token}`)})) :
                                next.handle(req);
   }
}
