import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

export const routes: Routes = [
    {
        path: 'login',
        loadChildren: 'src/app/components/login/login.module#LoginModule'
    },
    {
        path: 'home',
        loadChildren: 'src/app/components/home/home.module#HomeModule'
    },
    { path: '', redirectTo: '/login', pathMatch: 'full'}
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true });
