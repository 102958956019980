export class Especie {
    codmerca: number;
    descripcionmercaderia: string;
    tipoMercaderia: number;
    cosecha: string;
    tarifaxtonelada: number

    constructor(especie?: {
        codmerca: number;
        descripcionmercaderia: string;
        tipoMercaderia: number;
        cosecha: string;
        tarifaxtonelada: number
    },especie2?: {
        codMerca: number;
        descripcion: string;
    }) {
        if (especie) {
            this.codmerca = especie.codmerca;
            this.descripcionmercaderia = especie.descripcionmercaderia;
            this.tipoMercaderia = especie.tipoMercaderia;
            this.cosecha = especie.cosecha;
            this.tarifaxtonelada = especie.tarifaxtonelada
        } else if (especie2) {
            this.codmerca = especie2.codMerca;
            this.descripcionmercaderia = especie2.descripcion;
        } else {
            this.codmerca = null;
            this.descripcionmercaderia = null;
            this.tipoMercaderia = null;
            this.cosecha = null;
            this.tarifaxtonelada = null
        }
    }
}
