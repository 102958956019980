import * as XLSX from 'xlsx';
import zipcelx from 'zipcelx';
import { saveAs as importedSaveAs } from "file-saver";
import { Injectable } from "@angular/core";
import { AuthService } from "src/app/services/authService";
import { UtilsService } from "src/app/services/utilsService";
import { CartaPorteDetalle } from "src/app/models/cartaPorteDetalle";
import { CartaPorte } from "src/app/models/cartaPorte";
import { Analisis } from "src/app/models/analisis";

@Injectable()
export class FilesService {

    constructor(
        private authService: AuthService,
        private utilsService: UtilsService
    ) { }

    /**
     * Convertir xlsx a xls (excel viejo)
     */
    xlsxToXlsx = (dataBlob) => {
        var workbook = XLSX.readFile('unTest.xlsx');

        // XLSX.write(workbook, 'out.xlsb')
        const test = XLSX.writeFile(workbook, 'test.xls');
    };



    /**
     * Genera el excel de la posicion
     */
    descargarExcel = (dataExcel) => {
        const columnas = this.getColumnasExcel();

        const filas = dataExcel.map(
            carta => this.generateRowExcel(carta)
        );

        let data = [];

        data.push(columnas.map(col => {
            return {
                value: col,
                type: 'string'
            }
        }));

        filas.forEach(fila => data.push(fila));

        const config = {
            filename: this.getNombreArchivo('excel'),
            sheet: {
                data: data
            }
        };

        // Obtengo el xlsx
        zipcelx(config)(true).then(req => {
            // Viene en formato blob, asi que lo paso a arrayBuffer y dsps a xls
            var arrayBuffer;
            var fileReader = new FileReader();

            // En este evento del fileReader se hace la transformación, asi que pongo la lógica de lo que va a pasar acá cuando se invoque el onLoad
            fileReader.onload = (event) => {
                // Obtengo el arrayBuffer
                const eventTarget: any = event.target; // Esta linea con 'any' es necesaria porque typeScript tira error de tipo
                arrayBuffer = eventTarget.result;

                // Leo el arrayBuffer como excel formato xlsx
                const workbookXlsx = XLSX.read(arrayBuffer, { type: "array" });

                // Transformo el xlsx a xls y los descargo
                XLSX.writeFile(
                    workbookXlsx,
                    `${this.getNombreArchivo('excel')}.xls`

                );

            };

            // Proceso el blob a arrayBuffer
            fileReader.readAsArrayBuffer(req);

        });
    }

    /**
     * Crea un archivo txt del analisis y lo descarga
     */
    generarTxtAnalisis = (cartas: CartaPorte[]) => {


        const lineas = cartas
            .filter(carta => carta.analisis && carta.analisis.length > 0)
            .sort((c1, c2) => c1.PorteNro - c2.PorteNro)
            .map(carta =>
                carta.analisis
                    .map(
                        a => `${carta.portePrefijo}      ${carta.PorteNro}      ${carta.porteVagon}      ${a.anl_rubro}        ${a.Rbr_Descripcion}                ${a.anl_porc_analisis}      ${a.anl_kilos_merma}      ${a.anl_porc_merma}      ${a.anl_cantidad}`
                        // a => `${padding(carta.portePrefijo)()()}${padding(carta.PorteNro)()()}${padding(carta.porteVagon)()()}${padding(a.anl_rubro)()()}${padding(a.Rbr_Descripcion)()()}${padding(a.anl_porc_analisis)()()}${padding(a.anl_kilos_merma)()()}${padding(a.anl_porc_merma)()()}${padding(a.anl_cantidad)()()}`
                    )
                // .join()
            );

        const flatten = arr => arr.reduce(
            (a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []
        );


        const cuerpoTxt = flatten(lineas).join('\r\n');


        let blob = new Blob([cuerpoTxt], { type: "text/plain;charset=utf-8" });

        importedSaveAs(blob, this.getNombreArchivo('analisis'));


    };

    /**
     * Genera una row dada una carta
     */
    private generateRowExcel = (carta: CartaPorteDetalle) => {
        let kgs_merma = 0;

        carta.analisis.forEach(
            a => kgs_merma = kgs_merma + a.anl_kilos_merma
        );

        let netoAplic = carta.porte_kgs_neto - kgs_merma;

        // let columnaAj = `${carta.analiDet}${carta.obs_analisis ? ' ' + carta.obs_analisis : ''}${carta.entre_cp ? '@' : ''}${carta.entre_cp}`

        const parseAnalisis = (a: Analisis) =>
            `${a.rbr_abrev}: ${a.anl_porc_analisis}% ${a.anl_porc_merma !== 0 ? `M${a.anl_porc_merma}%` : ''}`;

        const theAnalisis = carta.analisis.map(a => parseAnalisis(a));
        //
        let columnaAj = `${theAnalisis.join(', ')}${carta.obs_analisis ? ' ' + carta.obs_analisis : ''}${carta.entre_cp ? '@' : ''}${carta.entre_cp}`

        if (columnaAj && columnaAj[0] === ' ') {
            columnaAj = columnaAj.substring(1)
        }
        
        return [
            {
                value: carta.Porte_prefijo.toString() + carta.Porte_nro.toString(),
                type: 'string'
            },
            {
                value: carta.porte_turno,
                type: 'number'
            },
            {
                value: carta.estado_posi_des,
                type: 'string'
            },
            {
                value: this.utilsService.convertDate(carta.porte_fecha_arr),
                type: 'string'
            },
            {
                value: carta.porte_cod_merca,
                type: 'number'
            },
            {
                value: carta.cereal,
                type: 'string'
            },
            {
                value: carta.porte_titular,
                type: 'string'
            },
            {
                value: carta.porte_cuit_remitente,
                type: 'string'
            },
            {
                value: carta.porte_interm,
                type: 'string'
            },
            {
                value: carta.porte_cuit_cyo,
                type: 'string'
            },
            {
                value: carta.porte_remi,
                type: 'string'
            },
            {
                value: carta.porte_cuit_cyo2,
                type: 'string'
            },
            {
                value: carta.corre,
                type: 'string'
            },
            {
                value: carta.Porte_cuit_corredor,
                type: 'string'
            },
            {
                value: carta.destinatariodeno,
                type: 'string'
            },
            {
                value: carta.Porte_cuit_destinatario,
                type: 'string'
            },
            {
                // value: carta.destin,
                value: carta.Pto_Razon,
                type: 'string'
            },
            {
                value: carta.Porte_cod_puerto,
                type: 'number'
            },
            {
                value: carta.entre,
                type: 'string'
            },
            {
                value: carta.Porte_cuit_entregador,
                type: 'string'
            },
            {
                value: carta.Porte_transp_razon,
                type: 'string'
            },
            {
                value: carta.porte_cuit_transp,
                type: 'string'
            },
            {
                value: carta.P_adic_razon_chofer,
                type: 'string'
            },
            {
                value: carta.P_adic_cuit_chofer,
                type: 'string'
            },
            {
                value: carta.proce,
                type: 'string'
            },
            {
                value: carta.P_adic_bruto_proc,
                type: 'number'
            },
            {
                value: carta.P_adic_tara_proc,
                type: 'number'
            },
            {
                value: carta.porte_kgs_procede,
                type: 'number'
            },
            {
                value: this.utilsService.convertDate(carta.porte_fecha_des),
                type: 'string'
            },
            {
                value: carta.porte_bruto,
                type: 'number'
            },
            {
                value: carta.porte_kgs_tara,
                type: 'number'
            },
            {
                value: carta.porte_kgs_neto,
                type: 'number'
            },
            {
                value: kgs_merma,
                type: 'number'
            },
            {
                value: netoAplic,
                type: 'number'
            },
            {
                value: carta.cld_nomenclatura,
                type: 'string'
            },
            {
                value: columnaAj,
                type: 'string'
            },
            {
                value: carta.porte_contrato,
                type: 'string'
            },
            {
                value: carta.P_adic_p_nro_cac,
                type: 'string'
            },
            {
                value: carta.porte_ctg,
                type: 'string'
            },
            {
                value: this.utilsService.convertDate(carta.porte_fecha_cp),
                type: 'string'
            },
            {
                value: this.utilsService.convertDate(carta.P_adic_fecha_venc),
                type: 'string'
            },

            {
                value: carta.getNroVagonOChasis() ,
                type: 'string'
            },
            {
                value: carta.P_adic_patente_acopl,
                type: 'string'
            },
            {
                value: carta.rt1,
                type: 'string'
            },
            {
                value: carta.cuit_rt1,
                type: 'string'
            },
            {
                value: carta.rt2,
                type: 'string'
            },
            {
                value: carta.cuit_rt2,
                type: 'string'
            },
            {
                value: '',
                type: 'string'
            },
            {
                value: '',
                type: 'string'
            },
            {
                value: carta.nom_corre2,
                type: 'string'
            },
            {
                value: carta.corre2,
                type: 'number'
            },
            {
                value: '',
                type: 'string'
            },
            {
                value: '',
                type: 'string'
            },

            {
                // value: carta.obs_estado,
                value: `${carta.obs_estado ? carta.obs_estado : ''} ${carta.Porte_observacion ? carta.Porte_observacion : ''}`,
                type: 'string'
            },
            {
                value: carta.Porte_nro_planta,
                type: 'number'
            },
            {
                value: carta.Porte_nro_pla_cyo1,
                type: 'number'
            },
            {
                value: carta.porte_nro_pla_cyo2,
                type: 'number'
            },
            {
                value: carta.Porte_cod_procedencia,
                type: 'number'
            },
            {
                value: carta.aut_puerto,
                type: 'string'
            },
            {
                value: carta.nroOperador,
                type: 'number'
            },
            {
                value: carta.nroSucursal,
                type: 'number'
            },
            {
                value: carta.nroOrden,
                type: 'number'
            },
            {
                value: carta.tipoDocumento,
                type: 'string'
            },
            {
                value: carta.nro_cupo,
                type: 'string'
            }
        ]
    }

    private getColumnasExcel = () => ['NroCP',
        'TURNO',
        'SITUACION',
        'FECHA_ARRIBO',
        'COD_MERCADERIA',
        'MERCADERIA',
        'TITULAR_CP',
        'CUIT_TITULAR_CP',
        'INTERMEDIARIO',
        'CUIT_INTERMEDIARIO',
        'RTE_COMERCIAL',
        'CUIT_RTE_COMERCIAL',
        'CORREDOR',
        'CUIT_CORREDOR',
        'DESTINATARIO',
        'CUIT_DESTINATARIO',
        'DESTINO',
        'COD_DESTINO',
        'ENTREGADOR',
        'CUIT_ENTREGADOR', 'TRANSPORTISTA', 'CUIT_TRANSPORTISTA', 'CHOFER', 'CUIT_CHOFER', 'LOCALIDAD_ORIGEN', 'BRUTO_PROC',
        'TARA_PROC',
        'NETO_PROC',
        'FECHA_DESCARGA',
        'BRUTO_DEST',
        'TARA_DEST',
        'NETO_DEST',
        'MERMA_KG',
        'NETO_CONVENIDO',
        'CALIDAD',
        'OBSERVACION',
        'CONTRATO',
        'CEE',
        'CTG',
        'FECHA_CARGA',
        'FECHA_VENCIMIENTO',
        'CHASIS',
        'ACOPLADO',
        'RTE1',
        'CUIT_RTE1',
        'RTE2',
        'CUIT_RTE2',
        'RTE3',
        'CUIT_RTE3',
        'COR1',
        'CUIT_COR1',
        'COR2',
        'CUIT_COR2',
        'OBS_PUERTO',
        'PTA_TIT',
        'PTA_INT',
        'PTA_RCO',
        'ONC_PROC',
        'AUT_PUERTO',
        'NROOPER',
        'NROSUC',
        'NROORD',
        'TIPODOC',
        'CUPO'        
    ];


    // nom_arch = "Posicion-" + DateTime.Now.ToString("yyMMdd") + "-" + DateTime.Now.ToString("HHmm") + ".xls"
    // nom_arch = "Analisis-" + DateTime.Now.ToString("yyMMdd") + "-" + DateTime.Now.ToString("HHmm") + ".txt"
    private getNombreArchivo = (tipo) => {

        const hoy = new Date();

        const year = hoy.getFullYear().toString().substring(2);
        const month = ((hoy.getMonth() + 1) >= 10) ? (hoy.getMonth() + 1).toString() : `0${(hoy.getMonth() + 1).toString()}`;
        const day = (hoy.getDate() >= 10) ? hoy.getDate().toString() : `0${hoy.getDate().toString()}`;
        const hours = (hoy.getHours() >= 10) ? hoy.getHours().toString() : `0${hoy.getHours().toString()}`;
        const minutes = (hoy.getMinutes() >= 10) ? hoy.getMinutes().toString() : `0${hoy.getMinutes().toString()}`;
        const yyMMdd = `${year}${month}${day}`;
        const HHmm = `${hours}${minutes}`;

        return (tipo === 'excel') ? `Posicion-${yyMMdd}-${HHmm}` : `Analisis-${yyMMdd}-${HHmm}`
    }

    /**
     * Pasa de xlsx a xls
     */
    parseXlsxToXls = (xlsfile) => {

    }

}
