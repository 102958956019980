import { Especie } from "./especie";

export class FiltrosModel {
    especie: Especie;
    destino: number;
    situacion: string;
    entregador: number;
    entrega: {
        propia: boolean;
        otras: boolean;
    };
    titular: number;
    intermediario: number;
    remComercial: number;
    corredor: number;
    destinatario: number;
    procedencia: number;
    estadoHistoria: number;

    constructor(filtros?: {
        especie: any;
        // especie: number;
        destino: number;
        situacion: string;
        entregador: number;
        entrega: any;
        titular: number;
        intermediario: number;
        remComercial: number;
        corredor: number;
        destinatario: number;
        procedencia: number;
        estadoHistoria: number;
    }){
        if (filtros) {
            this.especie = new Especie(null, filtros.especie);
            this.destino = filtros.destino;
            this.situacion = filtros.situacion;
            this.entregador = filtros.entregador;
            this.entrega = filtros.entrega;
            this.titular = filtros.titular;
            this.intermediario = filtros.intermediario;
            this.remComercial = filtros.remComercial;
            this.corredor = filtros.corredor;
            this.destinatario = filtros.destinatario;
            this.procedencia = filtros.procedencia;
            this.estadoHistoria = filtros.estadoHistoria;
        } else {
            this.especie = null;
            this.destino = null;
            this.situacion = null;
            this.entregador = null;
            // Por defecto en true
            this.entrega = { propia: true, otras: true };
            this.titular = null;
            this.intermediario = null;
            this.remComercial = null;
            this.corredor = null;
            this.destinatario = null;
            this.procedencia = null;
            this.estadoHistoria = null;
        }
    }

    /**
     * Retorna true si al menos un filtro esta activo
     */
    // someFilterIsActive = () => {
    //     return (this.especie || this.destino || this.situacion || this.entregador) ? true : false;
    // }
}
