import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class ConfigService {

    private lightTable = new BehaviorSubject<boolean>(false);

    constructor() { }

    toggleTableColor = () =>
        this.lightTable.next(
            !this.lightTable.value
        )

    getLightTableState = () => this.lightTable;


}
