import { Puerto } from "src/app/models/puerto";
import { Estado } from "src/app/models/estado";
import { PadronAgri } from "src/app/models/padronAgri";
import { Entregador } from "./entregador";
import { Procedencia } from "./procedencia";
import { Analisis } from "./analisis";
import { Especie } from "./especie";

export class CartaPorte {
    PorteNro: number;
    estado: Estado;
    porteTurno: number;
    titular: PadronAgri;
    remCom: PadronAgri;
    corre: string;
    cereal: string;
    puertoDestino: Puerto;
    destin: string;
    calidadCldNomenclatura: string;
    porteEstado: number;
    estadoPosiReal: string;
    analisis: Analisis[];
    entregador: Entregador;
    intermediario: PadronAgri;
    corredor: PadronAgri;
    destinatario: PadronAgri;
    procedencia: Procedencia;
    entreCp: String;
    porteFechaDes: Date;
    porteCodPuerto: number;
    portePatenteCamion: String;
    obsEstado: String;
    portePrefijo: number;
    porteVagon: number;
    porteKgsProcede: number;
    porteKgsNeto: number;
    obsAnalisis: String;
    autPuerto: String;
    porteTipo: number;
    especie: Especie;
    porteObservacion: String;
    corredor2: PadronAgri;
    enTramite: boolean;
    ruta: String;
    act_comentario: String;    
    rt1: string; // Hace referencia al remitente comercial 2 de la DB
    cuit_rt1: number;
    rt2: string; // Hace referencia al remitente comercial 3 de la DB
    cuit_rt2: number;    
    nroSucursal: number;
    nroOrden: number;
    nroCTG: string;

    constructor(cartaPorte?: {
        PorteNro: number;
        estado: any;
        porteTurno: number;
        titular: any;
        remCom: any;
        corre: string;
        cereal: string;
        puertoDestino: any;
        destin: string;
        calidadCldNomenclatura: string;
        porteEstado: number;
        estadoPosiReal: string;
        analisis: any[];
        rubroRbrAbrev: string;
        analisisAnlPorcAnalisis: number;
        analisisAnlPorcMerma: number;
        entregador: any;
        intermediario: any;
        corredor: any;
        destinatario: any;
        procedencia: any;
        entreCp: String;
        porteFechaDes: Date;
        porteCodPuerto: number;
        portePatenteCamion: String;
        obsEstado: String;
        portePrefijo: number;
        porteVagon: number;
        porteKgsProcede: number;
        porteKgsNeto: number;
        obsAnalisis: String;
        autPuerto: String;
        porteTipo: number;
        especie: any;
        porteObservacion: String;
        corredor2: any;
        enTramite: number;
        ruta: String;
        act_comentario: String;

        rt1: {cuit: number, denominacion: string};
        rt2: {cuit: number, denominacion: string};        
        nroSucursal: number;
        nroOrden: number;        
    }) {        
        if (cartaPorte) {
            this.PorteNro = cartaPorte.PorteNro;
            this.estado = new Estado(cartaPorte.estado);
            this.porteTurno = cartaPorte.porteTurno;
            this.titular = new PadronAgri(cartaPorte.titular);
            this.remCom = new PadronAgri(cartaPorte.remCom);
            this.corre = cartaPorte.corre;
            this.cereal = cartaPorte.cereal;
            this.puertoDestino = new Puerto(cartaPorte.puertoDestino);
            this.destin = cartaPorte.destin;
            this.calidadCldNomenclatura = cartaPorte.calidadCldNomenclatura;
            this.porteEstado = cartaPorte.porteEstado;
            this.estadoPosiReal = cartaPorte.estadoPosiReal;
            this.analisis = cartaPorte.analisis.map(ana => new Analisis(ana));
            this.entregador = new Entregador(cartaPorte.entregador);
            this.intermediario = new PadronAgri(cartaPorte.intermediario);
            this.corredor = new PadronAgri(cartaPorte.corredor);
            this.destinatario = new PadronAgri(cartaPorte.destinatario);
            this.procedencia = new Procedencia(cartaPorte.procedencia);
            this.entreCp = cartaPorte.entreCp;
            this.porteFechaDes = cartaPorte.porteFechaDes;
            this.porteCodPuerto = cartaPorte.porteCodPuerto;
            this.portePatenteCamion = cartaPorte.portePatenteCamion;
            this.obsEstado = cartaPorte.obsEstado;
            this.portePrefijo = cartaPorte.portePrefijo;
            this.porteVagon = cartaPorte.porteVagon;
            this.porteKgsProcede = cartaPorte.porteKgsProcede;
            this.porteKgsNeto = cartaPorte.porteKgsNeto;
            this.obsAnalisis = cartaPorte.obsAnalisis;
            this.autPuerto = cartaPorte.autPuerto;
            this.porteTipo = cartaPorte.porteTipo;
            this.especie = new Especie(null, cartaPorte.especie);
            this.porteObservacion = cartaPorte.porteObservacion;
            this.corredor2 = new PadronAgri(cartaPorte.corredor2);

            this.enTramite = cartaPorte.enTramite && cartaPorte.enTramite === 1;
            this.ruta = cartaPorte.ruta;
            this.act_comentario = cartaPorte.ruta;

            if(cartaPorte.rt1) {
                this.rt1 = cartaPorte.rt1.denominacion;
                this.cuit_rt1 = cartaPorte.rt1.cuit;
            }

            if(cartaPorte.rt2) {
                this.rt2 = cartaPorte.rt2.denominacion;
                this.cuit_rt2 = cartaPorte.rt2.cuit;
            }
            
            this.nroSucursal = cartaPorte.nroSucursal;
            this.nroOrden = cartaPorte.nroOrden;

            // Se usa para el filtrado de la grilla
            this.nroCTG = this.portePrefijo+''+this.PorteNro;
        } else {
            this.PorteNro = null;
            this.estado = new Estado();
            this.porteTurno = null;
            this.titular = new PadronAgri();
            this.remCom = new PadronAgri();
            this.corre = null;
            this.cereal = null;
            this.puertoDestino = new Puerto();;
            this.destin = null;
            this.calidadCldNomenclatura = null;
            this.porteEstado = null;
            this.estadoPosiReal = null;
            this.analisis = [];
            this.entregador = new Entregador();
            this.intermediario = new PadronAgri();
            this.corredor = new PadronAgri();
            this.destinatario = new PadronAgri();
            this.procedencia = new Procedencia();
            this.entreCp = null;
            this.porteFechaDes = null;
            this.porteCodPuerto = null;
            this.portePatenteCamion = null;
            this.obsEstado = null;
            this.portePrefijo = null;
            this.porteVagon = null;
            this.porteKgsProcede = null;
            this.porteKgsNeto = null;
            this.obsAnalisis = null;
            this.autPuerto = null;
            this.porteTipo = null;
            this.especie = null;
            this.porteObservacion = null;
            this.corredor2 = new PadronAgri();
            this.enTramite = false;
            this.act_comentario = cartaPorte.ruta;
            
            if(cartaPorte.rt1) {
                this.rt1 = cartaPorte.rt1.denominacion;
                this.cuit_rt1 = cartaPorte.rt1.cuit;
            }

            if(cartaPorte.rt2) {
                this.rt2 = cartaPorte.rt2.denominacion;
                this.cuit_rt2 = cartaPorte.rt2.cuit;
            }            
            this.nroSucursal = cartaPorte.nroSucursal;
            this.nroOrden = cartaPorte.nroOrden; 
        }
    }

    getNumeroCTG(){
        return this.portePrefijo+this.PorteNro;
    }   

    getNumeroCPE(){
        let sNroSucursal = '0000'+ this.nroSucursal;
        let sNroOrden = '0000000'+ this.nroOrden;        
        let sNroCPE = sNroSucursal.substring(sNroSucursal.length -5) + '-' + sNroOrden.substring(sNroOrden.length -8);
        return sNroCPE;
    }    

    getOtrosIntervinientes(){
        let inter1 = "";
        let inter2 = "";

        if(this.rt1){
            inter1 = "("+ this.cuit_rt1 + ") " + this.rt1;
        }

        if(this.rt2){
            inter2 = "("+ this.cuit_rt2 + ") " + this.rt2;
        }        

        return inter1 + " - " + inter2;
    }  

}
