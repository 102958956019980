// Angular
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { map } from "rxjs/operators";

// Proyecto
import { constants } from "../environment/constants";
//import { Acceso } from "../models/acceso";
import { Login } from "../models/login";
import { Observable, from, pipe } from "rxjs";
import { mocks } from "../environment/mock";

import { Puerto } from "src/app/models/puerto";
import { TipoAccion } from "src/app/models/tipoAccion";
import { CartaPorte } from "src/app/models/cartaPorte";
import { CartaPorteDetalle } from "src/app/models/cartaPorteDetalle";
import { Accion } from "src/app/models/accion";
import { CondicionRecibo } from "../models/condicionRecibo";
import { CamionDescarga } from "../models/camionDescarga";
import { Especie } from "../models/especie";
import { Noticia } from "../models/noticia";

@Injectable()
export class AuthService {
    constructor(public http: HttpClient) {}

    /**
     * Loguea un usuario
     */
    login = (usuario) => (clave) => {
        return this.http
            .post<Login>(
                `${constants.urlBase}/usuario/login/${usuario}`,
                null,
                {
                    headers: new HttpHeaders({
                        clave: clave,
                    }),
                }
            )
            .toPromise();
    };

    /**
     * Obtengo la posicion mappeada (TODO: Le voy a pasar los entregadores en formato: cuit_entre1, cuit_entre2, ... , cuit_entreN)
     */
    getPosicion = () =>
        this.http.get(`${constants.urlBase}/cartaPorte/posicion`).pipe(
            map((posicionItemsResp: any) =>
                posicionItemsResp.map((itemResp) => {
                    const cloneItemResp = Object.assign({}, itemResp);
                    if (
                        cloneItemResp.estado.estado === 7 &&
                        cloneItemResp.porteEstado === 3
                    ) {
                        cloneItemResp.porteEstado = 5;
                        cloneItemResp.estadoPosiReal = "Desviado";
                    }

                    return new CartaPorte(cloneItemResp);
                })
            )
        );

    /**
     * Obtengo la posicion mappeada (TODO: Le voy a pasar los entregadores en formato: cuit_entre1, cuit_entre2, ... , cuit_entreN)
     */
    getPosicionPuertos = () =>
        this.http.get(`${constants.urlBase}/cartaPorte/posicion-puertos`).pipe(
            map((posicionItemsResp: any) =>
                posicionItemsResp.map((itemResp) => {
                    const cloneItemResp = Object.assign({}, itemResp);
                    if (
                        cloneItemResp.estado.estado === 7 &&
                        cloneItemResp.porteEstado === 3
                    ) {
                        cloneItemResp.porteEstado = 5;
                        cloneItemResp.estadoPosiReal = "Desviado";
                    }

                    return new CartaPorte(cloneItemResp);
                })
            )
        );

    /**
     * Obtengo la descarga en determinada fecha
     * @param fechaDesde En formato dd/mm/aaaa
     * @param fechaHasta En formato dd/mm/aaaa
     */
    getHistoria = (fechaDesde) => (fechaHasta) =>
        this.http
            .get(
                `${constants.urlBase}/cartaPorte/historia?fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`
            )
            .pipe(
                map((posicionItemsResp: any) =>
                    posicionItemsResp.map((itemResp) => {
                        const cloneItemResp = Object.assign({}, itemResp);

                        if (
                            cloneItemResp.estado.estado === 7 &&
                            cloneItemResp.porteEstado === 3
                        ) {
                            cloneItemResp.porteEstado = 5;
                            cloneItemResp.estadoPosiReal = "Desviado";
                        }

                        return new CartaPorte(cloneItemResp);
                    })
                )
            );

    /**
     * Obtengo la data necesaria para el excel
     */
    getDataExcel = (tipoTabla) => (fechaDesde?) => (fechaHasta?) =>
        this.http
            .get(
                `${constants.urlBase}/cartaPorte/dataExcel/${tipoTabla}${
                    tipoTabla === "historia"
                        ? "?fechaDesde=" +
                          fechaDesde +
                          "&fechaHasta=" +
                          fechaHasta
                        : ""
                }`
            )
            .pipe(
                map((dataExcel: any) =>
                    dataExcel.map((itemResp) => new CartaPorteDetalle(itemResp))
                )
            );

    /**
     * Este metodo descarga un archivo zip desde el back-end con las imagenes de las Cartes de porte de la posicion
     */
    descargarZIPImagenesPosicion(
        horaDesde: string,
        horaHasta: string,
        codDestino: string,
        codDestinatario: string
    ): Observable<any> {
        let url = `${constants.urlBase}/cartaPorte/imagenes-posicion?horaDesde=${horaDesde}&horaHasta=${horaHasta}`;
        url = url + "&destino=" + (codDestino ? codDestino : "null");
        url =
            url +
            "&destinatario=" +
            (codDestinatario ? codDestinatario : "null");
        return this.http.get(url, { responseType: "blob" });
    }

    /**
     * Este metodo descarga un archivo zip desde el back-end con las imagenes de las Cartes de porte del historial
     */
    descargarZIPImagenesDescargas(
        fechaDesde: string,
        fechaHasta: string,
        codDestino: String,
        codDestinatario: string
    ): Observable<any> {
        let url = `${constants.urlBase}/cartaPorte/imagenes-descarga?fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`;
        url = url + "&destino=" + (codDestino ? codDestino : "null");
        url =
            url +
            "&destinatario=" +
            (codDestinatario ? codDestinatario : "null");
        return this.http.get(url, { responseType: "arraybuffer" });
    }

    /**
     * Obtengo lista de destinos
     */
    getDestinos = () =>
        this.http
            .get(`${constants.urlBase}/cartaPorte/destinos`)
            .pipe(
                map((data: any) => data.map((itemResp) => new Puerto(itemResp)))
            );

    /**
     * Setea una nueva accion, agregando el registro en Actualizaciones y cambiando el estaod e la carta
     * TODO: Sería bueno setear las condiciones de abajo en el backend
     */
    setAccion =
        (carta: CartaPorte) =>
        (datosAccion: {
            puertoNuevo: Puerto;
            comentario: string;
            tipoAccion: TipoAccion;
        }) =>
            this.http.post(`${constants.urlBase}/cartaPorte/nueva-accion`, {
                portePrefijo: carta.portePrefijo,
                porteNro: carta.PorteNro,
                cuitEntre: carta.entregador.cuit,
                porteCodPuerto: carta.porteCodPuerto,
                porteVagon: carta.porteVagon,
                nuevoEstado:
                    datosAccion.tipoAccion.tipo === 1
                        ? 10
                        : datosAccion.tipoAccion.tipo === 2
                        ? 11
                        : datosAccion.tipoAccion.tipo === 4
                        ? 8
                        : 0,
                puertoViejo: carta.puertoDestino.ptoCodinterno
                    ? carta.puertoDestino.ptoCodinterno
                    : 0,
                puertoNuevo:
                    datosAccion.tipoAccion.tipo === 2
                        ? datosAccion.puertoNuevo &&
                          datosAccion.puertoNuevo.ptoCodinterno
                            ? datosAccion.puertoNuevo.ptoCodinterno
                            : 0
                        : 0,
                comentario: datosAccion.comentario,
                tipoAccion: datosAccion.tipoAccion.tipo,
                porteTurno: carta.porteTurno,
                codmerca:
                    carta.especie && carta.especie.codmerca
                        ? carta.especie.codmerca
                        : 0,
                puertoNuevoDesc:
                    datosAccion.tipoAccion.tipo === 2
                        ? datosAccion.puertoNuevo &&
                          datosAccion.puertoNuevo.ptoCodinterno
                            ? datosAccion.puertoNuevo.ptoRazon
                            : ""
                        : "",
            });

    /**
     * Obtengo una carta de la historia por su numero
     */
    getHistoriaPorNroOrPatente = (nroCarta) =>
        this.http
            .get(`${constants.urlBase}/cartaPorte/historia/${nroCarta}`)
            .pipe(
                map((cartasEncontradas: any) =>
                    cartasEncontradas.map(
                        (itemResp) => new CartaPorteDetalle(itemResp)
                    )
                )
            );

    /**
     * Obtener el historial de acciones del usuario
     */
    getHistorialAcciones = (fechaDesde: string) => (fechaHasta: string) =>
        this.http.get<Accion[]>(
            `${constants.urlBase}/usuario/historial-acciones/?fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`
        );

    /**
     * Obtengo condiciones de recibo
     */
    getCondicionesRecibo = () =>
        this.http.get<CondicionRecibo[]>(
            `${constants.urlBase}/cartaPorte/condiciones-recibo`
        );

    /**
     * Obtengo camiones a la descarga
     */
    getCamionesDescarga = () =>
        this.http.get<CamionDescarga[]>(
            `${constants.urlBase}/cartaPorte/camiones-descarga`
        );

    /**
     * Obtengo todas las especies
     */
    getEspecies = () =>
        this.http.get<Especie[]>(`${constants.urlBase}/cartaPorte/especies`);

    /**
     * Obtener el historial de acciones del usuario
     * @param fechaDesde Formato yyyyMMddHHmmss. Ejemplo: 20180917140000 (17/09/2018 14:00:00)
     */
    getHistorialNoticias = (fechaDesde: string) =>
        this.http.get<Noticia[]>(
            `${constants.urlBase}/usuario/historial-noticias/?fechaDesde=${fechaDesde}`
        );

    /**
     * Setea una nueva accion, agregando el registro en Actualizaciones y cambiando el estaod e la carta
     */
    setEnTramite = (carta: CartaPorte, newState: boolean) =>
        this.http.post(`${constants.urlBase}/cartaPorte/carta-en-tramite`, {
            portePrefijo: carta.portePrefijo,
            porteNro: carta.PorteNro,
            cuitEntre: carta.entregador.cuit,
            porteCodPuerto: carta.porteCodPuerto,
            porteVagon: carta.porteVagon,
            enTramite: newState ? 1 : 0,
        });
}
