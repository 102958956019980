// Angular
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable, from, pipe } from "rxjs";

// Proyecto
import { constants } from "../environment/constants";
import { Contacto } from "../models/contacto";

@Injectable()
export class ContactoService {

    constructor(public http: HttpClient) {}

    actualizarContactos = (usuario) => (contactos: Contacto[]) => {
        return this.http.post(`${constants.urlBase}/usuario/contactos/actualizar/${usuario}`, contactos).toPromise();
    };

}