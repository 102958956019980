export class Entregador {
    cuit: number;
    denominacion: string;

    constructor(entregador?: {
        cuit: number;
        descripcion: string;
    }) {
        if (entregador) {
            this.cuit = entregador.cuit;
            this.denominacion = entregador.descripcion;
        } else {
            this.cuit = null;
            this.denominacion = null;
        }
    }

}
