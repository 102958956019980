import { Injectable } from "@angular/core";
import { CartaPorte } from "src/app/models/cartaPorte";
import { Analisis } from "src/app/models/analisis";
import { MatDialog } from "@angular/material";
import { DefaultDialog } from "src/app/components/others/defaultDialog/defaultDialog";

@Injectable()
export class UtilsService {

    constructor(
        public dialog: MatDialog
    ) { }

    /**
     * Abre el dialog default, generalmente se usa para mostrar erores
     */
    openDefaultDialog = (titulo: string) => (cuerpo) => {
        let dialogRef = this.dialog.open(DefaultDialog, {
            data: {
                titulo: titulo,
                cuerpo: cuerpo
            }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }


    /**
     * Transforma una fecha
     */
/*    convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat);
        const resp = [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/');
        return resp.includes('NaN') ? '' : resp;
    }*/

    convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        if(inputFormat){
            var d = new Date(inputFormat.replace("ene","jan")
                                        .replace("abr","apr")
                                        .replace("ago","aug")
                                        .replace("dic","dec")
                            );
            const resp = [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/');
            return resp.includes('NaN') ? '' : resp;    
        } 
        return '';
    }    
    
    /**
     * Formateo date to dd/mm/aaaa
     */
    dateToDdMmAaaa = (fechaDate: Date) => {

        console.log(`${fechaDate.getDate() > 9 ? fechaDate.getDate() : '0'+fechaDate.getDate()}/${fechaDate.getMonth() > 9 ? fechaDate.getMonth() + 1 : '0'+(fechaDate.getMonth() + 1)}/${fechaDate.getFullYear()}`)

        return `${fechaDate.getDate() > 9 ? fechaDate.getDate() : '0'+fechaDate.getDate()}/${fechaDate.getMonth() > 9 ? fechaDate.getMonth() + 1 : '0'+(fechaDate.getMonth() + 1)}/${fechaDate.getFullYear()}`
    }

    /**
     * Get ayer
     */
    getYesterday = () => {
        const hoy = new Date();
        let ayer = new Date(hoy);
        ayer.setDate(hoy.getDate() - 1);
        return ayer;
    }

    capitalizeFirstLetter = (value: string) => `${value[0].toUpperCase()}${value.substring(1)}`

}
