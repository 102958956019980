export class PadronAgri {
    cuit: number;
    denominacion: string;

    constructor(padronAgri?: {
        cuit: number;
        denominacion: string;
    }) {
        if (padronAgri) {
            this.cuit = padronAgri.cuit;
            this.denominacion = padronAgri.denominacion;
        } else {
            this.cuit = null;
            this.denominacion = null;
        }
    }

}
